@import 'variables.scss';

nav.side-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    margin-top: 10vh;
    // border: $test;
    margin-left: 2vw;
    

}

.first-part,
.second-part,
.third-part {
    display: flex;
    flex-direction: column;
    margin-top: 0;


}

.side-bar>.first-part>a,
.side-bar>.second-part>a,
.side-bar>.third-part>a {
    font-family: $main-font;
    color: $main-color;
    margin: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 1vw;
    padding-left: 1vw;
    border: $menu-border;
    background-color: lightgray;
    width: 12vw;



}

.first-part>a:hover,
.second-part>a:hover,
.third-part>a:hover {
    background-color: $accent-color;
    color: white;
    text-decoration: none;



}

@media only screen and (max-width: $med-screen) {

    nav.side-bar {
        display: flex;
        flex-direction: column;
        align-content: center;
        // border: $test;
        z-index: inherit;
        width: 100%;
        height: 10rem;
        padding: 0;
        margin: 0;
    }

    .side-bar>.first-part,
    .side-bar>.second-part,
    .side-bar>.third-part {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        width: 100vw;
        // margin-left: 4vw;
        // border: $test;
    }

    .side-bar>.first-part>a,
    .side-bar>.second-part>a,
    .side-bar>.third-part>a {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;

    }
}