@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Train+One&family=Kaushan+Script&family=Bebas+Neue&family=Oswald:wght@200;300;500;600;700&family=Roboto+Condensed:wght@300;400&display=swap');


$main-font: Archivo Narrow;
$title-font: BEBAS NEUE;
$header-font: Oswald;
$fancy-font: Train One;
$main-color:#4C4E52;
$test: solid red;
$menu-border: 0.5px solid white;
$menu-bg: lightgrey;
$accent-color: #0e9e8b;
$dark-accent: #096E61;
$light-accent: #b6e1dc;
$chart-border: 3px solid white;
$footer-height: 17rem;
$dark-main-color:#393b3e;
$med-screen: 920px;
$sm-screen: 600px;