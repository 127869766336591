@import './styles/variables';

* {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

html, body
{
    margin: 0px; padding: 0px;
    width:100%;
}

body {
  display: flex;
  flex-direction: column ;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $main-font;
  font-size: large;
  padding: 0%;
  margin: 0%;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  // border: $test;
  // background-size : cover;

}

button:hover {
  cursor: pointer;
}

p:visited,
a:visited {
  color: $main-color;
}

a:link {
  text-decoration: none;
  color: $main-color;
}

a:hover {
  color: $accent-color;
  // text-decoration: underline;
}

h1 {
  font-family: $header-font;
  font-size: 3rem;
  font-weight: bold;
  // border: $test;
}

h2 {
  font-family: $header-font;
  font-size: 2rem;
  color: $main-color // font-weight: bold;
}

////  for Product index page    ////

article {
  // border: $test;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
 


  h1 {
    // border: $test;
    width: 100%;
    // margin-left: 22vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }


  .img-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    // border: $test;

    .image-title {
      // border: $test;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5vw;
      margin-left: 1vw;
      margin-right: 1vw;
    }

    h2 {
      margin-top: 0%;
      margin-bottom: 1wvh;
      // padding-bottom: 10%;
      font-family: $header-font;
      font-weight: 400;
      // border: $test;
    }


    img {
      // border: $test;
      height: 10vw;
      margin: 3vw;
      margin-top: 4vh; 
    }
  }
}

/// for place an order page ///

div .order-page {
  // border: $test;

  h1 {
    padding-bottom: 4vh;
    // text-decoration: underline;
  }

  ul.order-type {
    // border: $test;
    list-style-type: circle;
    display: list-item !important;


  }

  ul > li {
    // border: $test;
    display: list-item;
    list-style-type: circle;

  }
}

@media only screen and (max-width: $med-screen) {

  html
{
    margin: 0px; padding: 0px;
    width:100%;
    // border: $test;
}

  article {
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;

  }

  article > h1 {
    margin-bottom: 5rem;
    // border: $test;
  }

  article .img-container {
    // border: $test;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 20vw;
   
  }

  .img-container > .image-title {
    // border: $test;
    // width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 10vw;


  }

  .img-container > .image-title > a > img,
  .img-container > .image-title-reliance > a > img,
  .img-container > .image-title-maxum > a > img {
    width: 40vw;
    height: auto;
    // border: $test;
    margin-top: 0;
    // margin-top: 10vw;
    // margin-bottom: 10vw;
    
  }

  .img-container > .image-title-reliance > a > img {
    padding-top: 25vw;
    // border: $test;

  }

  .img-container > .image-title-maxum > a > img  {
    padding-top: 15vw;
  }


  .img-container > .image-title > h2 > a {
    // border: $test;
    font-size: xx-large;
    // margin-bottom: 20vw;
  

  }
}