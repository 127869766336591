@import 'variables.scss';

.sheet-lead {
//  border: $test;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $main-color;
    margin-bottom: 8vh;
    margin-top: 3vh;
    text-align: left;

    p {
        text-align: left;
        margin-top: 2vh;
        margin-bottom: 1vh;
        width: 70%;


    }

    h3 {
        margin-top: 4vh;
        margin-bottom: 1vh;
        width: 70%;
      
    }

    .table-sheet-lead {
        text-align: center;
        border-collapse: collapse;
        margin-top: 3vh;
      

         .main-header {
            color: white;
            font-size: xx-large;
   
        }
    
        th {
            border: $chart-border;
            color: white;
            background-color: black;
            padding: 1vw;
        }
    
        td {
            border: $chart-border;
            color: $main-color;
            background-color: $menu-bg;
        }
    
    }
    
}


@media only screen and (max-width: $med-screen) {

    .sheet-lead {
          width: 100%;

        p {
            width: 90%;
        }

        h1 {
            text-align: center;
        }

        h3 {
              width: 90%;
        }  

        .table-sheet-lead {
        
            margin-left: 3vw;
            margin-right: 3vw;


            .main-header {
                font-size: x-large;

            }
        }
    }



}