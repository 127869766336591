@import 'variables.scss';

* {
    margin-right: 0;
    padding-right: 0;
}

.order {
    // border: $test;
    display: flex;
    flex-direction: column;
    background-color: lightgrey;
    margin-top: 0;
    width: 100%;
    margin-right: 0;
    padding-right: 0;



    .form-frame {
        border: none;
        margin: 3vw;
        margin-left: 20vw;
        margin-right: 20vw;
        background-color: white;
        border-radius: 15px;

        h1 {
            margin-right: 3vw;
            margin-left: 3vw;
        }
    }

}

form {
    // margin: 3rem;
    //  border: $test;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // border: $test;

    hr {
        color: black;
    }

    h3 {
        color: $main-color;
        font-size: x-large;
        font-weight: 900;
        width: 100%;
        margin-top: 3vh;
        font-family: $main-font;
    }

    div {
        // border: $test;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        margin-left: 0;
        width: 100%;

        label {

            // border: $test;
            font-family: $main-font;
            color: $main-color;
            // display: inline-block;
            width: 20vw;
            word-wrap: break-word;
            text-align: end;
            // margin-right: 1vw;

        }

        .unit {
            // border: $test;
            display: flex;
            width: 30%;
        }

    }
}

.dropdown {
    // border: $test;
    margin-left: 0.75VW;
    padding-left: 0;

    label {
        // border: $test;
        width: 32%;
        padding-right: 1vw;
    }
}

.dropdown>select {
    font-size: large;
    width: 21.25vw;
    height: 7.5vh;
    text-align: center;
    // border: $test;
    margin-left: 0;

    option {
        font-size: large;
        background-color: $light-accent;

    }

}

input,
textarea,
select {
    border: 1px solid $main-color;
    border-radius: 3px;
    height: 4.5vh;
    width: 20vw;
    margin: 1vw;
    padding: 1vw;
    // border: $test;
    font-family: $main-font;
    font-size: large;
}

// select {
//     margin-left: 0;
// }

textarea {
    // vertical-align: middle;
    width: 20vw;
    height: 7rem;
    // display: table;
}

input:focus,
textarea:focus {
    // border: 2px soild #11cfb5;
    background-color: $light-accent;
    border: solid 2px $accent-color;

    // background-color: $accent-color;

}

fieldset {
    border-left: none;
    border-right: none;
    width: 100%;
    margin-top: 2vh;
    padding-top: 0;
    margin-bottom: 3vh;
    padding-bottom: 2vh;
    color: $main-color;
    margin-left: 0;
    // border: $test;

    div {
        // border: $test;
        margin-left: .75vw
    }
}

button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $accent-color;
    color: white;
    width: 20rem;
    height: 2.5rem;
    border-radius: 20px;
    font-family: $header-font;
    font-size: x-large;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.order-submit {
    // border: $test;
    margin-left: 30%;
}


.custom-label {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 11%;
    width: 20.2%;

    label {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 0;
        // border: $test;
    }
}

@media only screen and (max-width: $med-screen) {

    .order>.form-frame {
        // border: $test;
        border: none;
        margin-left: 10vw;
        margin-right: 10vw;


        p {
            margin-left: 2vw;
            margin-right: 2vw;

        }

        form>div {
            // border: $test;
            margin-left: 10vw;

            textarea {
                // border: $test;
                width: 30vw;
            }
        }

        form>fieldset>div {
            // border: $test;
            margin-left: 5vw;

            select {
                // border: $test;
                width: 32vw;
            }

            .custom-label {
                // border: $test;
                width: 20%;
                margin-left: 5%;
            }

        }

    }

    input {
        height: 1.5rem;
        width: 30vw;
        // border: $test;
    }

    .order-submit {
        // border: $test;
        margin-left: 25vw;
        width: 25vw;
        min-width: 100px;
    }

    .dropdown {
        // border: $test;
        margin-left: 0vw;
        width: 100%;
        // padding-left: 0;

        label {
            // border: $test;
            width: 25%;
            padding-right: 1vw;
        }

        select {
            // border: $test;
            height: 2.5rem;
            width: 22vw
        }
    }
}


@media only screen and (max-width: $sm-screen) {


    .order>.form-frame {
        // border: $test;
        border: none;
        margin-left: 5vw;
        margin-right: 5vw;

        form>div {
            // border: $test;
            margin-left: 5vw;

            /////// Input - additional notes
            textarea {
                // border: $test;
                width: 50vw;
            }
        }

        form>fieldset {

            /////// Input - position - Thickness 
            .dropdown {
                // border: $test;
                margin-left: 0%;

            }

            div {
                // border: $test;
                margin-left: 5vw;


                /////// Input - Thickness
                select {
                    // border: $test;
                    width: 46.5vw;
                }

                /////// Input - position - custom size 
                .custom-label {
                    // border: $test;
                    width: 20%;
                    margin-left: 0%;
                }

            }
        }

    }

    /////// Input - all minus 2
    input {
        height: 1.5rem;
        width: 50vw;
        // border: $test;
    }

    .order-submit {
        // border: $test;
        margin-left: 25vw;
        width: 40vw;
        min-width: 100px;
    }

    .dropdown {
        // border: $test;

        label {
            // border: $test;
            width: 25%;
            padding-right: 1vw;
        }

        select {
            // border: $test;
            height: 2.5rem;
            width: 46.5vw
        }
    }
}


// .order {
//     width: 100%;
// }

// .order>.form-frame {
//     border: none;
//     margin-left: 2vw;
//     margin-right: 2vw;


//     form>div {

//         margin-left: 20.5vw;

//         input {
//             width: 40vw;
//             // border: $test;

//         }

//         textarea {
//             width: 45vw;
//             // border: $test;
//         }
//     }

//     fieldset {

//         .custom-input {
//             // border: $test;
//             margin-left: 0;
//         }

//         div {

//             select {
//                 width: 42vw;
//                 border: $test;
//             }

//             .custom-label {
//                 // border: $test;
//                 width: 30%;
//                 margin-left: 0%;
//             }

//         }

//         input,
//         textarea,
//         select {
//             width: 40vw;

//         }



//         label {
//             // border:$test;
//             width: 25vw;
//         }

//     }
// }

// .order-submit {
//     // border: $test;
//     margin-left: 20vw;
//     width: 45vw;
// }