@import 'variables.scss';

.about {
    // border: $test; 
    font-family: $main-font;
    color: $main-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8vh;

        // // border: $test;
        margin-top: 5vh;
        justify-content: center;
        align-items: flex-start;
      
       
        .robot {
            // border: $test;
            width: 20vw;
            height: 30vw;
            border-radius: 12px;
            margin-left: 5vw;
        }


        .text {
            // border: $test;
            display: flex;
            flex-direction: column;
            width: 30vw;
            padding: 0;
            text-align: left;


            h1{
                margin: 2vw
            }

        


    }
}

@media only screen and (max-width: $med-screen) {

    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        // border: $test;
}

.about > .text {
    // border: $test;
    width: 70%
}

.about > .robot {
    width: 40vw;
    height:auto;
    margin-top: 5vh;
}
    
}

@media only screen and (max-width: $sm-screen) {
    
    .about > .robot {
        width: 60vw;
        height:auto;
        margin-left: 0px;  
    }

    .about > .text {
        width: 70vw;
  
    }
}