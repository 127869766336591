@import 'variables.scss';


/// This is used for these pages: Brick&Blocks and Pipe&Tube ///

.container {
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $main-color;
    margin-bottom: 5vh;
    margin-top: 3vh;
    text-align: left;

    h1 {
        width: 70%;
        margin-bottom: 2vh;
        text-align: center;

    }

    p {
        width: 70%;
        text-align: left;
        margin-top: 2vh;
        margin-bottom: 1vh;

    }

    h3 {
        width: 70%;
        margin-top: 4vh;
        margin-bottom: 1vh;
    }

}



.first-col {
    font-size: xx-large;

}

.main-table {
    text-align: center;
    border-collapse: collapse;
    margin-top: 3vh;
    margin-left: 1vw;
    margin-right: 1vw;

    th {
        border: $chart-border;
        color: white;
        background-color: black;
        padding: 1vw;
    }

    .even-row {
        border-bottom: $chart-border;
        padding-bottom: 1vh;
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .odd-row {
        padding-top: 1vh;
    }

    td {
        border-right: $chart-border;
        color: $main-color;
        background-color: $menu-bg;
    }
}

@media only screen and (max-width: $med-screen) {

    .container {

        h1 {
            width: 90%;
        }

        p {
            width: 90%;
        }

        h3 {
            width: 90%;
        }

    }
}