@import 'variables.scss';

///this is used for these pages: flashings&caps and lead anodes///

.main-section {
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $main-color;
    margin-bottom: 8vh;
    margin-top: 3vh;
    text-align: left;

    p {
        text-align: left;
        margin-top: 2vh;
        margin-bottom: 1vh;
        width: 70%;

    }

    h3 {
        margin-top: 4vh;
        margin-bottom: 1vh;
        width: 70%;
    }
}


.main-section > .list-section {
    // border: $test;
    display: flex;
    flex-direction: row;
    // width: 20%;
    justify-content: space-evenly;
    // margin-left: 30vw;
    // padding-left: 0 ;
    width: 70%;
    // margin-left: 0%;
    white-space: nowrap;
    align-content: flex-end;


    ul {
        // border: $test;
        margin: 0;
        // padding: 0;
        // margin-right: 3vw;
            // padding: 0;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: space-between !important;

        margin-top: 2vh;

        // align-self: flex-start;

        // h4 {
        //     display: flex;
        //     justify-content: center;
        // }

        li {
            // border: $test;
            width: 100%;
            display: list-item;
            // padding-right: 0vw;
            // margin-left: 12vw;
            
        }
    }
}


h2 {
    text-align: center;
    margin-top: 5vh;
}

img.img-flashing {
    // border: $test;
    margin-top: 5vh;
    width: 30vw;
}

img.grid-anode {
    // border: $test;
    width: 50vw;
    margin-top: 2vw;

}



@media only screen and (max-width: $med-screen) {

    .main-section {
          width: 100%;

        p {
            width: 90%;
        }

        h1 {
            text-align: center;
        }

        h3 {
              width: 90%;
        }  
    }


    .main-section > .list-section {
        // border: $test;
        display: flex;
        flex-direction: row;
        width: 90%;
        margin-top: 2vh;
        margin-left: 8vw;

        ul {
            // border: $test;
            // display: flex;
            margin: 0;
            // margin-right: 3vw;
            // margin-left: 0%;
            padding: 0;
            // margin-top: 2vh;
            // width: 100%;


            // h4 {   
            //     border: $test;
            //     display: flex;
            //     justify-content: center;
            // }

            li {
                // border: $test;
                width: 100%;
                // display: list-item;
                // margin-right: 50px;
                // margin-left: 0vw;
                // margin-right: 2vw;
                white-space: normal;
            }
        }
    }


    img.img-flashing {
        // border: $test;
        margin-top: 5vh;
        width: 40vw;
    }
    img.grid-anode {
        // border: $test;
        width: 80vw;
        margin-top: 2vw;
    
    }

}