@import 'variables.scss';

.main-section {
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-items: center;
    width: 100%;
    // width: 70%;
    color: $main-color;
    // width: 60vw;
    // margin-left: 10vw;
    // margin-right: 24vw;
    margin-bottom: 8vh;
    margin-top: 3vh;
    text-align: left;

    p {
        text-align: left;
        margin-top: 2vh;
        margin-bottom: 1vh;
        width: 70%;

    }

    h3 {
        margin-top: 4vh;
        margin-bottom: 1vh;
        width: 70%;
    }
}


.list {
    // border: $test;
    display: flex;
    justify-content: center;
    width: 70%;

    // margin-left: 0%;

    .left-list {
        // border: $test;
        // margin: 0;
        margin-left: 2vw;
        // margin-left: 0%;
        // padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        // align-self: flex-start;

        h4 {
            // border: $test;
            display: flex;
            justify-content: flex-start;
            padding-left: 22%;
        }

        li {
            // border: $test;
            width: 90%;
            display: list-item;
            // padding-right: 0vw;
            margin-left: 1vw;
            // margin-right: 2vw;
        }
    }

    .right-list {
        // border: $test;
        // margin: 0;
        // margin-right: 3vw;
        margin-left: 0%;
        // padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        // align-self: flex-start;

        h4 {
            // border: $test;
            display: flex;
            justify-content: flex-start;
            padding-left: 22%;
        }

        li {
            // border: $test;
            width: 90%;
            display: list-item;
            // padding-right: 0vw;
            margin-left: 0vw;
            // margin-right: 2vw;
        }
    }


}


h2 {
    text-align: center;
    margin-top: 5vh;
}


img.bends {
    width: 24vw;
    margin-top: 2vw;
    // border:$test;
}

img.stubs {
    width: 10vw;
    margin-top: 3.5vw;
    margin-left: 4vw;
    // border:$test;
}

@media only screen and (max-width: $med-screen) {

    .main-section {
        // border: $test;
        width: 100%;

        p {
            width: 90%;
        }

        h1 {
            text-align: center;
        }

        h3 {
            width: 90%;
        }
    }

    .main-section>.list {
        // border: $test;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        margin-top: 2vw;
        margin-left: 20%;

        ul {
            margin-top: 0;
            margin-bottom: 0;

            h4 {
                // border: $test;
                display: flex;
                justify-content: flex-start;
            }

            li {
                // border: $test;
                width: 70%;
                margin-left: 3vw;

            }
        }

    }

    img.bends {
        width: 45vw;
        margin-top: 2vw;
        // border:$test;
    }

    img.stubs {
        width: 22vw;
        margin-top: 2vw;
        margin-left: 10vw;
        // border:$test;
    }

}