@import 'variables.scss';

///this is used for these pages: babbitt, silfos and sash weights ///

.main {
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-items: center;
    width: 100%;
    // width: 70%;
    color: $main-color;
    // width: 60vw;
    // margin-left: 10vw;
    // margin-right: 24vw;
    margin-bottom: 8vh;
    margin-top: 3vh;
    text-align: left;

    p, h2 {
        text-align: left;
        margin-top: 2vh;
        margin-bottom: 1vh;
        width: 70%;

    }

    h3, h1 {
        margin-top: 4vh;
        margin-bottom: 1vh;
        width: 70%;
        
        // border: $test;
    }
}

// babbit page has diff classname for list section //
.main > .list {
    // border: $test;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    width: 55%;

    // padding-left: 0 ;
    // width: 60%;
    margin-top: 2vh;


    ul {
        // border: $test;
        margin: 0;
        padding: 0;
        // margin-right: 3vw;
        // margin-left: 10%;
        // padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        
        // margin-top: 2vh;
        // align-self: flex-start;

        h4 {
            display: flex;
            justify-content: center;
        }

        li {
            // border: $test;
            // width: 100%;
            display: list-item;
            // padding-right: 0vw;
            margin-left: 4vw;
            white-space: normal;
        }
    }
}


h2 {
    text-align: center;
    margin-top: 5vh;
}

h3.external-link {
    text-align: center;
}


.img-sash-weight {
    width: 30vw;
}


.img-reliance-logo {
    height: 20vh;
}

img.silfos {
    margin-top: 2vh;
    width: 40vw;
}

img.img-maxum-logo {
    margin-top: 4vw;
    width: 15vw;

}

@media only screen and (max-width: $med-screen) {

    .main {
        // border: $test;
          width: 100%;

        p {
            width: 90%;
        }

        h1 {
            text-align: center;
        }

        h3 {
              width: 90%;
        }  
    }


    .main > .list {
        // border: $test;
        display: flex;
        // flex-direction: column;
        // align-items: center;
        width: 70%;

    }

    .main > .img-sash-weight {
        width: 70vw;
        // border: $test;
    }

    img.silfos {
       width: 70vw;
    }
}