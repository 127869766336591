@import 'variables.scss';

/* ---------------- Navbar and burger menu ------------------ */
nav {
    width: 100%;
    height: 6vw;

    // border-bottom: 1px solid black;
    display: flex;
    justify-content: flex-end;
    // padding: 1em;
    padding-bottom: 1.5rem;
    z-index: 2;
}


nav .leftSide {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    // justify-content: flex-start;
    position: absolute;
    top: 1.5vw;
    left: 5vw;
    // background-color: red;

}

//logo
.leftSide > a > img {
    display: flex;
    width: 9vw;
    height: auto;
    margin: 0px;
    margin-bottom: 1.5vw;
    margin-right: 2vw;
    // margin-left: 5vw;
}

a>h1.title {
    font-family: $title-font;
    display: flex;
    font-weight: bold;
    font-size: 5vw;
    margin: 0px;
    // padding-top: 1.5rem;
    white-space: nowrap;
    color: $main-color;
    letter-spacing: .15vw;
}

.buttonMenu {
    display: flex;
    // flex-direction: row;
    font-size: 1.3vw;
    height: 130%;
    align-items: flex-end;
    margin: 0rem;
    padding-bottom: 0;
    // margin-right: 4vw;
    // margin-left: 10vw;
    white-space: nowrap;
    // border: $test;
}

.product-menu {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;

}

.drop-item {
    display: flex;
    font-family: $main-font;
    margin: 0px;
    padding: 0px;
    padding-right: 2vh;
    padding-left: 2vh;
    background-color: $accent-color;
    border: $menu-border;
    color: white;
}

p.button1 {
    display: flex;
    padding-right: 1vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    padding-bottom: 0rem;
    font-family: $main-font;
}

a>p.buttonLast {
    margin-right: 4vw;
}

p.button1:hover {
    text-decoration: underline;
    text-underline-offset: 0.3em;
}

.product-menu:hover .dropdown-content {
    display: block;
}

.product-menu:hover .drop-btn {
    border: $menu-border;
}

.dropdown-content p:hover {
    background-color: $main-color;
    color: white;
}


.menuLinks {
    display: none;
}

.burger-menu {
    display: none;

}


.burger-bar {

    display: flex;
    border: solid 5px darkgray;
    justify-content: space-around;
    position: relative;
    top: 15px;
    right: 7px;
    // flex-direction: column;
    // justify-content: center;
    // width: 1%;
    // height: 8%;
    background-color: darkgray;
    border-radius: 0.5em;
    // border: 4px solid $main-color;
    padding-top: 0;
    padding-bottom: 0;
    // margin-top: 5px;
    // margin-bottom: -5px;
    // float: left;
    // margin: 3px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;

}

.menu {
    width: 20vw;
    min-width: 150px;
    // height: 100vh;
    background-color: $accent-color;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    white-space: nowrap;
    // border: $test;
}

.menuLinks {

    display: inline;
    margin: 0;
    width: 18%;
    // flex-direction: column;
    text-align: center;
    // list-style-position: inside;
    position: fixed;
    top: 15%;
    right: 0vw;
    list-style: none;
    // border: $test;

    li {
        display: flex;
        width: 86%;
        justify-content: center;
        //  border: $test;
        padding-top: 15px;
        padding-right: 5px;
        padding-left: 0px;
        font-size: x-large;
        font-weight: bold;
        letter-spacing: .2vw;

        a {
            color: white;
        }

        a:hover {
            color: $main-color;
        }

    }
}

/* ------------- sliding menu ------------------ */
.hidden {
    display: none;
}

.visible {
    display: inherit;


}

/* ----- animations--------- */
/* clicked */
// .burger-bar.clicked:nth-child(1) {
//     transform: rotate(45deg) translate(0.05em, 1.4em);
//     transition: ease-out 0.5s;
// }

// .burger-bar.clicked:nth-child(2) {
//     transform: scale(0.1);
//     transition: ease-out 0.5s;
// }

// .burger-bar.clicked:nth-child(3) {
//     transform: rotate(135deg) translate(.4em, 1em);
//     transition: ease-out 0.5s;
// }

.burger-bar.clicked:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(10px, 9px);
    -moz-transform: rotate(45deg) translate(10px, 9px);
    -o-transform: rotate(45deg) translate(10px, 9px);
    transform: rotate(45deg) translate(10px, 9px);
    width: 50px;

}

.burger-bar.clicked:nth-child(2) {
    // top: 200px;
    width: 0%;
    left: 0%;
}

.burger-bar.clicked:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(18px, -18px);
    -moz-transform: rotate(-45deg) translate(18px, -18px);
    -o-transform: rotate(-45deg) translate(18px, -18px);
    transform: rotate(-45deg) translate(18px, -18px);
    width: 50px;
}

burger-bar.clicked:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
    // border: $test;
    height: 100px;
    width: 100%;
}

hr {
// margin-top: 0;
// margin-bottom: 0;
margin: 0;
padding: 0;
}


@media only screen and (max-width: $med-screen) {

    nav {

        min-height: 4%;
        padding-bottom: 5vw;
        // margin-bottom: 5vw;

    }

    .buttonMenu {
        display: none;
    }

    .burger-menu {
        display: flex;
        // height: 10%;
        // border: $test;
        height: 7%;
        width: 8%;
        // margin-top: 1.25em;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding-top: 1vw;
        padding-bottom: 0;
        z-index: 3;
        margin-top: 1.5%;
        margin-right: 1.5%;
        position: fixed;

        div {
            width: 100%;
            height: 10px;
            margin-bottom: 10px;
        }

    }

    
    .burger-menu:hover {
        cursor: pointer;
    }
    
    .burger-bar {
        position: relative;
        top: 1.5vw;
    }

    .leftSide > a > img {
        width: 13vw;
        height: auto;
        // border: $test;
        padding-bottom: 0.5vw;
        margin-top: 10px;
    }

    a>h1.title {
        font-size: 7vw;
        // padding-bottom: 2vw;
        // border: $test;
    }

    .menuLinks {
        width: 130px;
        // border: $test;
    }

    hr {
        margin-bottom: 2vw;
    }

}

@media only screen and (max-width: $sm-screen) {

    nav {
        padding-bottom: 6vw;
        height: 40px;
        // border: $test;
        
    }

    nav .leftSide {
        // border: $test;
        display: flex;
        margin-left: 0px;
        padding-left: 0%;
        padding-top: 1vw;
        justify-content: flex-start;


    }

   .leftSide > a > img  {
        
    width: 15vw;
    display: flex;
        // height: auto;
        // border: $test;
        padding-bottom: 1.5vw;
        // margin-top: 10px;
        margin-left: 0px;
        padding-left: 0%;

        


    }

    a > h1.title {
        font-size: 7.5vw;
        padding-bottom: 1vw;
        // border: $test;
    }



    .burger-menu {
        // width: 4em;
        // margin-top: 1.25em;
        // flex-direction: column;
        // align-items: center;
        // justify-content: space-evenly;
        padding-top: 1vw;
        padding-bottom: 0;
        z-index: 3;
        margin-top: 10px;
        margin-right: 8px;
        position: fixed;
        height: 40px;
        width: 40px;
        // border: $test;

        div {

            height: 100%;
            margin-bottom: 5px;
        }


    }

    .burger-bar {
        position: relative;
        top: 2px;
        border: none;
    }
    
.burger-bar.clicked:nth-child(2) {
    // top: 200px;
    width: 0%;
    left: -25%
}

.burger-bar.clicked:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(4px, 11px);
    -moz-transform: rotate(45deg) translate(4px, 11px);
    -o-transform: rotate(45deg) translate(4px, 11px);
    transform: rotate(45deg) translate(4px, 11px);
    width: 40px;

}

.burger-bar.clicked:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(9px, -15px);
    -moz-transform: rotate(-45deg) translate(9px, -15px);
    -o-transform: rotate(-45deg) translate(9px, -15px);
    transform: rotate(-45deg) translate(9px, -15px);
    width: 40px;
}

burger-bar.clicked:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

}