@import 'variables.scss';

.Products {
    display: flex;
    // flex-direction: column;
    // align-items: center;
    

}

.side-bar {
    display: flex;
    flex-direction: row;

}


@media only screen and (max-width: $med-screen) {

    .Products {

        display: flex;
        flex-direction: column;
        align-items: center;


    }
}