@import './styles/variables';

.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}


.page-contents {
  padding-bottom: $footer-height;

}