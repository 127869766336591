@import 'variables.scss';


.paragraph {
    // font-family: $main-font;
    // color: $main-color;
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10vw;
    margin-right: 10vw;
    z-index: 1;

    p {
        width: 50vw;


    }

    a {
        text-decoration: underline;
        text-underline-offset: 0.25em;
    }

    .web-size {
        // border: $test;
        width: 75vw;
        margin-top: 3vw;
        margin-bottom: 3vw;


        img {
            width: 100%;
            border-radius: 10px;

        }
    }

    .mobile-size {
        display: none;
    }

}

h1 {
    display: flex;
    font-family: $header-font;
    font-weight: 400;
    color: $main-color;
    justify-content: center;
    margin-bottom: 0px;
}


.section>a>img {
    // border: $test;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-left: 1vw;
    width: 15vw;
}

.products {
    display: flex;
    justify-content: center;
    margin-top: 0px;

}

.section {
    // border: $test;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    color: $main-color;

}


a>.logo {
    // border: $test;
    height: 10vh;
    margin-bottom: 0%;
}

a>h2 {
    // border: $test;
    margin-top: 2vh;
}

@media only screen and (max-width: $med-screen) {

    .Home {
        width: 100vw;
        margin: 0;
    }

    .paragraph {
        display: flex;
        margin-left: 0;

        width: 100vw;
        // border: $test;

        .mobile-size {
            display: flex;
            justify-content: center;
            width: 100vw;
            margin-top: 2vw;
            margin-bottom: 2vw;
            // border: $test;

            img {
                width: 100%;
            }
        }

        .web-size {
            display: none;
            border: $test;
            width: 75vw;
            margin-top: 3vw;
            margin-bottom: 3vw;

        }
    }

    .paragraph>p {
        width: 80vw;
    }

    .products {
        display: flex;
        flex-direction: column;

        a>img {
            width: 70vw;
            margin: 2.5rem;
        }
    }
}